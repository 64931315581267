import { css, LitElement } from "lit";
import { html, unsafeStatic } from 'lit/static-html.js';

import circleUpSolid from '../../assets/images/icons/circle_up_solid.svg';
import circleUpRegular from '../../assets/images/icons/circle_up_regular.svg';

export class UpvoteButton extends LitElement {
    static styles = css`
        :host {
            width: 1em;
            height: 1em;
        }
        .upvote {
            width: 1em;
            height: 1em;
        }
    `;

    static properties = {
        selected: { type: Boolean },
        disabled: { type: Boolean },
    };

    constructor() {
        super();

        this.selected = false;
        this.disabled = false;
    }

    render = () => {
        const icon = this.selected ? circleUpSolid : circleUpRegular;
        return html`<div class="upvote" @click="${this._click}" ?disabled="${this.disabled}">${unsafeStatic(icon)}</div>`;
    }

    _click = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.disabled) return;
        this.dispatchEvent(new Event('upvote', { detail: { selected: this.selected } }));
    }
}

customElements.define('upvote-button', UpvoteButton);
