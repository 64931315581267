import { css, LitElement } from "lit";
import { html, unsafeStatic } from 'lit/static-html.js';

import circleDownSolid from '../../assets/images/icons/circle_down_solid.svg';
import circleDownRegular from '../../assets/images/icons/circle_down_regular.svg';

export class DownvoteButton extends LitElement {
    static styles = css`
        :host {
            width: 1em;
            height: 1em;
        }
        .downvote {
            width: 1em;
            height: 1em;
        }
    `;

    static properties = {
        selected: { type: Boolean },
        disabled: { type: Boolean },
    };

    constructor() {
        super();

        this.selected = false;
        this.disabled = false;
    }

    render = () => {
        const icon = this.selected ? circleDownSolid : circleDownRegular;
        return html`<div class="downvote" @click="${this._click}" ?disabled="${this.disabled}">${unsafeStatic(icon)}</div>`;
    }

    _click = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.disabled) return;
        this.dispatchEvent(new Event('downvote', { detail: { selected: this.selected } }));
    }
}

customElements.define('downvote-button', DownvoteButton);
