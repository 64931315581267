import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class NavBarController extends Controller {
  static targets = ["search", "menu", "search", "notifications", "notification"];

  connect() {
    console.debug("NavBarController connected");
    document.addEventListener("keydown", this.focusSearchHandler);
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideMenu);
    document.removeEventListener("keydown", this.focusSearchHandler);
    document.removeEventListener("click", this.onClickOutsideNotifications);
  }

  openMenu() {
    document.addEventListener("click", this.onClickOutsideMenu);
  }

  // Put cursor in the search input when cmd + k is pressed
  focusSearchHandler = (e) => {
    if (e.key === "k" && e.metaKey) {
      this.searchTarget.focus();
    }
  }

  onClickOutsideMenu = (e) => {
    if (!this.menuTarget.contains(e.target)) {
      this.menuTarget.removeAttribute("open");
      document.removeEventListener("click", this.onClickOutsideMenu);
    }
  }

  onClickOutsideNotifications = (e) => {
    if (!this.notificationsTarget.contains(e.target)) {
      this.notificationsTarget.removeAttribute("open");
      document.removeEventListener("click", this.onClickOutsideNotifications);
    }
  }

  onSearch(e) {
    if (e.key === "Enter") {
      const path = this.searchTarget.dataset.searchPath;
      const url = new URL(path, window.location.origin);
      url.searchParams.set("query", e.target.value);
      window.location.href = url.toString();
    }
  }

  onNotificationsClick() {
    document.addEventListener("click", this.onClickOutsideNotifications);
    const notificationIds = Array.from(this.notificationTargets).map(target => target.dataset.id);
    if (notificationIds.length > 0) {
      axios.post("/notifications/mark_as_read", { notification_ids: notificationIds });
    }
  }
}
