import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class OpinionController extends Controller {
    static targets = ["upvote", "downvote", "votecount", "opinion"];

    upvote() {
        axios.post(`/opinions/${this.opinionTarget.dataset.opinionId}/upvote`);
        this.toggleUpArrow();
    }

    downvote() {
        axios.post(`/opinions/${this.opinionTarget.dataset.opinionId}/downvote`);
        this.toggleDownArrow();
    }

    toggleUpArrow() {
        if (this.downvoteTargets.some(d => d.selected)) {
            this.downvoteTargets.forEach(d => d.selected = false);
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) + 2;
            });
        } else if (this.upvoteTarget.selected) {
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) - 1;
            });
        } else {
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) + 1;
            });
        }
        this.upvoteTargets.forEach(u => u.selected = !u.selected);
    }

    toggleDownArrow() {
        if (this.upvoteTargets.some(u => u.selected)) {
            this.upvoteTargets.forEach(u => u.selected = false);
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) - 2;
            });
        } else if (this.downvoteTargets.some(d => d.selected)) {
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) + 1;
            });
        } else {
            this.votecountTargets.forEach(votecount => {
                votecount.innerText = parseInt(votecount.innerText) - 1;
            });
        }
        this.downvoteTargets.forEach(d => d.selected = !d.selected);
    }

    toggleCollapse(event) {
        event.target.closest(".collapse").classList.toggle("collapse-open");
        event.target.closest(".collapse").classList.toggle("collapse-close");
    }
}
