import { Controller } from "@hotwired/stimulus";

export default class OpinionEditController extends Controller {
  static targets = ["sourcesList", "sourceTemplate"];

  connect() {
    console.log("opinion_edit_controller connected");
  }

  addSource(event) {
    event.preventDefault();
    const sourceItem = this.sourceTemplateTarget.content.cloneNode(true);
    this.sourcesListTarget.appendChild(sourceItem);
  }

  removeSource(event) {
    event.preventDefault();
    event.target.closest("div").remove();
  }

}
