import { LitElement, html } from 'lit';

class Avatar extends LitElement {
  static properties = {
    src: { type: String },
    size: { type: String },
    username: { type: String },
  }

  constructor() {
    super();
    this.size = '12';
    this.innerHTML = ''; // Remove anything added by Turbo cache
  }

  createRenderRoot = () => this;

  placeholderAvatar = (username) => html`
    <div class="avatar placeholder">
      <div class="w-${this.size} bg-neutral text-neutral-content rounded-full">
        ${username.split(' ').map(name => name[0].toUpperCase()).join('')}
      </div>
    </div>
  `;

  imageAvatar = () => html`
    <div class="avatar">
      <div class="w-${this.size} rounded-full">
        <img src="${this.src}" />
      </div>
    </div>
  `;

  render = () => html`${this.src ? this.imageAvatar() : this.placeholderAvatar(this.username)}`;

}

customElements.define('avatar-component', Avatar);
