import { Controller } from "@hotwired/stimulus";

export default class OrganizationNewController extends Controller {
  static targets = ["name", "slug"];

  connect() {
    console.debug('OrganizationNewController connected');
  }

  updateSlug(ev) {
    ev.preventDefault();
    if (!this.nameTarget.value.match(/^[a-zA-Z0-9_\- ]+$/)) {
      this.nameTarget.value = this.nameTarget.value.replace(/[^a-zA-Z0-9_\- ]/g, '');
    }

    const name = this.nameTarget.value;
    const slug = name.toLowerCase().replace(/ /g, '-');
    this.slugTarget.value = slug;
  }
}
