import { Controller } from "@hotwired/stimulus";

export default class TopicMessageController extends Controller {
  connect() {
    if (+this.element.dataset.topicMessageUserId === window.globals?.current_user?.id) {
      this.element.classList.add("chat-end");
    } else {
      this.element.classList.add("chat-start");
    }
  }
}
