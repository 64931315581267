import { Controller } from "@hotwired/stimulus";

export default class TopicController extends Controller {
    static targets = ["positions", "positionFields"];

    connect() {
        console.debug(`TopicController connected`);
    }    

    new() {
        const positionFields = this.positionFieldsTarget.content.cloneNode(true);
        const index = this.positionFieldsTarget.dataset.index;
        const name = `topic[positions_attributes][${index}][text]`;
        positionFields.querySelector('.position-text').name = name;
        this.positionFieldsTarget.dataset.index = index;

        this.positionFieldsTarget.dataset.index = parseInt(index) + 1;
        this.positionsTarget.appendChild(positionFields);
    }
}