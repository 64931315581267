import { Controller } from "@hotwired/stimulus";

export default class UserController extends Controller {
  static targets = ["avatarPreview", "avatarPreviewSkeleton", "bioCharCount"];

  connect() {
    console.debug("UserController connected");
  }

  updateAvatar(event) {
    if (this.debounceTimer) clearTimeout(this.debounceTimer);
    
    this.debounceTimer = setTimeout(() => {
      const avatarUrl = event.target.value;
      if (this.hasAvatarPreviewTarget) {
        this.avatarPreviewTarget.src = avatarUrl;
        this.avatarPreviewTarget.classList.remove("hidden");
        this.avatarPreviewSkeletonTarget.classList.add("hidden");
      }
    }, 500);
  }

  avatarPreviewError(event) {
    console.log("avatarPreviewError called");
    if (this.hasAvatarPreviewTarget) {
      this.avatarPreviewTarget.classList.add("hidden");
      this.avatarPreviewSkeletonTarget.classList.remove("hidden");
    }
  }

  updateBio(event) {
    const bio = event.target.value;
    this.bioCharCountTarget.textContent = bio.length;
  }
}
