// Import all the channels to be used by Action Cable
import "./topic_channel";

class ChannelStore {
  constructor() {
    this.channels = new Map();
  }

  channel(channelName, params) {
    const key = `${channelName}:${JSON.stringify(params)}`;
    if (!this.channels.has(key)) {
      this.channels.set(key, createConsumer().subscriptions.create(channelName, params));
    }
    return this.channels.get(key);
  }

  removeChannel(channelName, params) {
    const key = `${channelName}:${JSON.stringify(params)}`;
    if (this.channels.has(key)) {
      this.channels.get(key).unsubscribe();
      this.channels.delete(key);
    }
  }
}

const channelStore = new ChannelStore();

export { channelStore };
