import { Controller } from "@hotwired/stimulus";

export default class ThemeController extends Controller {
  static targets = ["theme"];
  change(event) {
    const checked = event.target.checked;
    const theme = checked ? "dark" : "corporate";
    document.documentElement.setAttribute("data-theme", theme);
    this.themeTarget.value = theme;
    localStorage.setItem("theme", theme);
  }

  connect() {
    console.debug("Theme controller connected");
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      this.themeTarget.checked = true;
    }
  }
}
