import { Controller } from "@hotwired/stimulus";

export default class PositionController extends Controller {
    static targets = ['position', 'left', 'right', 'leftArrow', 'rightArrow'];

    sliderClass = ['flex-initial', 'w-0', 'overflow-hidden'];
    sliderButtonClass = ['hidden'];

    delete() {
        // hide the position
        this.positionTarget.style.display = 'none';

        // add a hidden input to the position so that the server knows to delete it
        const input = document.createElement('input');
        input.type = 'hidden';

        const index = this.positionTarget.dataset.index;
        input.name = `topic[positions_attributes][${index}][_destroy]`;
        input.value = '1';
        this.positionTarget.appendChild(input);
    }

    openPros() {
        if (this.#alreadyOnRight()) {
            this.#showBothSides();
        } else {
            this.#showPros();
        }
    }

    openCons() {
        if (this.#alreadyOnLeft()) {
            this.#showBothSides();
        } else {
            this.#showCons();
        }
    }

    containsMultipleClasses(element, classes) {
        return classes.every(c => element.classList.contains(c));
    }

    addMultipleClasses(element, classes) {
        classes.forEach(c => element.classList.add(c));
    }

    removeMultipleClasses(element, classes) {
        classes.forEach(c => element.classList.remove(c));
    }

    #alreadyOnLeft() {
        return this.containsMultipleClasses(this.rightTarget, this.sliderClass) && !this.containsMultipleClasses(this.leftTarget, this.sliderClass);
    }

    #alreadyOnRight() {
        return this.containsMultipleClasses(this.leftTarget, this.sliderClass) && !this.containsMultipleClasses(this.rightTarget, this.sliderClass);
    }

    #showBothSides() {
        this.removeMultipleClasses(this.leftTarget, this.sliderClass);
        this.removeMultipleClasses(this.rightTarget, this.sliderClass);
        this.#showBothButtons();
    }

    #showPros() {
        this.removeMultipleClasses(this.leftTarget, this.sliderClass);
        this.addMultipleClasses(this.rightTarget, this.sliderClass);
        this.#showOnlyLeftButton();
    }

    #showCons() {
        this.addMultipleClasses(this.leftTarget, this.sliderClass);
        this.removeMultipleClasses(this.rightTarget, this.sliderClass);
        this.#showOnlyRightButton();
    }

    #showOnlyLeftButton() {
        this.removeMultipleClasses(this.leftArrowTarget, this.sliderButtonClass);
        this.addMultipleClasses(this.rightArrowTarget, this.sliderButtonClass);
    }

    #showOnlyRightButton() {
        this.addMultipleClasses(this.leftArrowTarget, this.sliderButtonClass);
        this.removeMultipleClasses(this.rightArrowTarget, this.sliderButtonClass);
    }

    #showBothButtons() {
        this.removeMultipleClasses(this.leftArrowTarget, this.sliderButtonClass);
        this.removeMultipleClasses(this.rightArrowTarget, this.sliderButtonClass);
    }
}
