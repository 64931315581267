import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "name", "slug" ]

  updateSlug() {
    const name = this.nameTarget.value
    const slug = name.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '')
    this.slugTarget.value = slug
  }
}
