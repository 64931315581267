import { Controller } from "@hotwired/stimulus";
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default class TopicStatController extends Controller {
  static targets = ["renderArea"];

  connect() {
    this.renderChart();
  }

  renderChart() {
    const data = JSON.parse(this.element.dataset.chartjsData);
    this.chart = new Chart(this.renderAreaTarget, {
      type: "bar",
      data: data,
    });

  }
}
