import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class TopicsController extends Controller {
    static targets = [
        "upvote",
        "downvote",
        "votecount",
    ];

    connect() {
        console.debug(`TopicsController connected`);
    }    

    toggleUpArrow() {
        if (this.downvoteTarget.selected) {
            this.downvoteTarget.selected = false;
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) + 2;
        } else if (this.upvoteTarget.selected) {
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) - 1;
        } else {
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) + 1;
        }
        this.upvoteTarget.selected = !this.upvoteTarget.selected;
    }

    toggleDownArrow() {
        if (this.upvoteTarget.selected) {
            this.upvoteTarget.selected = false;
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) - 2;
        } else if (this.downvoteTarget.selected) {
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) + 1;
        } else {
            this.votecountTarget.innerText = parseInt(this.votecountTarget.innerText) - 1;
        }
        this.downvoteTarget.selected = !this.downvoteTarget.selected;
    }

    upvote(event) {
        event.stopPropagation();
        axios.post(`/topics/${this.upvoteTarget.dataset.topicId}/upvote`)
            .then(response => {
                this.toggleUpArrow();
            })
            .catch(error => {
                console.log(error);
            });
    }

    downvote(event) {
        event.stopPropagation();
        axios.post(`/topics/${this.upvoteTarget.dataset.topicId}/downvote`)
            .then(response => {
                this.toggleDownArrow();
            })
            .catch(error => {
                console.log(error);
            });
    }
}
