import { Controller } from "@hotwired/stimulus"

export default class TopicAiRecommendationController extends Controller {
  static targets = ["aiRecommendationContent"];
  connect() {
    console.log("TopicAiRecommendationController connected");
  }

  clear() {
    this.aiRecommendationContentTarget.innerHTML = "";
  }
}
