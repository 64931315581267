import { Controller } from "@hotwired/stimulus";

export default class TopicMessagesController extends Controller {
  static targets = ["bodyInput"];

  create(event) {
    if (event.detail.success) {
      this.bodyInputTarget.value = "";
    }
  }
}
